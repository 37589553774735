import React from 'react'

// assets
import playbookKV from "../../assets/images/press-kit/playbook-KV.svg"

const PlaybookSection = () => {
  return (
    <div id="playbook-section">

      <div className="kv-container">
        <div className="text-container">
          <h2 className="heading">Servbees<span className="trademark-text">™</span> Brand Playbook</h2>
          <p className="text">Get access to our assets and details on the proper way to love and use them for your promotional materials.</p>
          <a href="/assets/press-kit/Servbees-Brand-Guidelines-2022.pdf" className="download" download>Download Servbees Brand Playbook</a>
        </div>
        <img src={playbookKV} alt="" />
      </div>


    </div>
  )
}

export default PlaybookSection
import React from "react"

// components
import Layout from "../components/layout"
import SEO from "../components/seo"
import IntroSection from "../components/sections/press-kit-intro-section.js"
import DownloadSection from "../components/sections/press-kit-download-section.js"
import PlaybookSection from "../components/sections/press-kit-playbook-section.js"

const PressKit = () => {

  return (
    <Layout className="bg-design vector-dash blue-footer" style={{paddingTop: 0, backgroundColor: "#F9F9F9"}}>
      <SEO
        pageTitle="Digital Press Kit"
        title="Digital Press Kit | Servbees"
        description="Let's team up! We welcome collaborations, media and digital content partnerships, B2B alliances, and more."
      />
        <div className="press-kit">
          <IntroSection />
          <DownloadSection />
          <PlaybookSection />
        </div>
    </Layout>
  )
}

export default PressKit

import React from "react"
import Helmet from "react-helmet"

const siteUrl =  process.env.GATSBY_SITE_URL || 'https://servbees.com';

const SEO = props => {
  const pageTitle = props.pageTitle
  const title = props.title
  const imageUrl = props.imageUrl
  const description = props.description
  const ogUrl = props.ogUrl
  const ogImage = props.ogImage
  const size = props.size

  return (
    <Helmet
      htmlAttributes={{
        lang: `en`,
      }}
      title={pageTitle}
    >
      <title>{title}</title>
      <meta
        name="google-site-verification"
        content="lWFgfHFT84yJeSJlCCjk65ZJoOpBZ4iQFUG6jghO090"
      />

      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@servbees" />
      <meta name="twitter:creator" content="@servbees" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={siteUrl + imageUrl} />

      <meta property="og:title" content={title} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={siteUrl + imageUrl} />
      <meta property="og:image:width" content={size} />
      <meta property="og:image:height" content={size} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:url" content={ogImage} />
      <meta property="og:image:secure_url" content={ogImage} />
      <meta property="og:image:alt" content="Servbees" />
    </Helmet>
  )
}

export default SEO

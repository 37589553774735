import React from "react"
import ReactHtmlParser from "react-html-parser"
import {saveAs} from "file-saver"


// assets
import servbees from "../../assets/images/press-kit/servbees.svg"
import beebo from "../../assets/images/press-kit/beebo.svg"
import palette from "../../assets/images/press-kit/palette.svg"
import stickers from "../../assets/images/press-kit/stickers.svg"
import appscreens from "../../assets/images/press-kit/app-screens.png"
import posters from "../../assets/images/press-kit/posters.svg"
import iconDownload from "../../assets/images/press-kit/download-icon.svg"

const downloadables = [
  {
    thumbnail: servbees,
    label: `Servbees<span className="trademark-text">™</span> Logo`,
    link: "/assets/press-kit/Servbees-Logo.zip",
    filename: 'Servbees-Logo',
    type: 'zip'
  },
  {
    thumbnail: beebo,
    label: `Beebo`,
    link: "/assets/press-kit/Beebo-Images.zip",
    filename: 'Beebo-Images',
    type: 'zip'
  },
  {
    thumbnail: palette,
    label: `Palette`,
    link: "/assets/press-kit/Servbees-Palette.pdf",
    filename: 'Servbees-Palette',
    type: 'pdf'
  },
  {
    thumbnail: stickers,
    label: `Stickers`,
    link: "/assets/press-kit/Servbees-Sticker-Pack-A4_FINAL.pdf",
    filename: 'Servbees-Sticker-Pack-A4_FINAL',
    type: 'pdf'
  },
  {
    thumbnail: appscreens,
    label: `App Screens`,
    link: "/assets/press-kit/App-Screens.png",
    filename: 'App-Screens',
    type: 'png'
  },
  {
    thumbnail: posters,
    label: `Posters`,
    link: "/assets/press-kit/A4-Poster.pdf",
    filename: 'A4-Poster',
    type: 'pdf'
  },
]

const DownloadSection = () => {

  return (
    <div id="download-section">
      <h2>Download our assets</h2>
      <p>
        Get the the assets you need below and hit us up at{" "}
        <a href="mailto:hello@servbees.com">hello@servbees.com</a> for a quick
        chat on buzzing opportunities we can explore.{" "}
      </p>

      <div className="download-grid">
        {downloadables.map((asset, i) => {
          return (
            <a key={i} className="grid-item" onClick={() => saveAs(`${asset.link}`, asset.filename, asset.type)} download>
              <div className="img-wrapper">
                <img
                  src={asset.thumbnail}
                  style={
                    asset.label === "App Screens"
                      ? {
                          position: "relative",
                          top: 28,
                          left: 16,
                          width: 322,
                          height: 342,
                        }
                      : {}
                  }
                  alt=""
                />
              </div>
              <div
                className="label"
                style={
                  asset.label === "App Screens"
                    ? { position: "absolute", bottom: 0 }
                    : {}
                }
              >
                {ReactHtmlParser(asset.label)}
                <div className="download-icon-wrapper">
                  <img
                    src={iconDownload}
                    alt=""
                  />
                </div>
              </div>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default DownloadSection

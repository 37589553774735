import React from 'react'

// assets
import KV from "../../assets/images/press-kit/KV.svg"
import KVMobile from "../../assets/images/press-kit/kv-m.svg"

const IntroSection = () => {
  return (
    <div id="intro-section">

      <div className="bg-gradient" />

      <h2>Partner with Servbees!</h2>
      <p className="intro-text">This Friendly Neighborhood Pagkakakita-App is now open to collaborations, media promotions, digital content partnerships, and partner merchants.</p>

      <div className="img-wrapper">
        <img src={KV} className="press-kit-img" alt="Digital Press Kit Servbees" />
        <img src={KVMobile} className="press-kit-img-mobile" alt="Digital Press Kit Servbees" />
      </div>

    </div>
  )
}

export default IntroSection